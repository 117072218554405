import React, { useState, useEffect } from "react";
import {
  Space,
  Button,
  Modal,
  Input,
  message,
  Spin,
  Select,
  Alert,
  Checkbox,
} from "antd";
import { useDispatch } from "react-redux";
import { isLogged, getAuthToken } from "../../../helpers/account";
import { api } from "../../../config/api";
import {
  MailOutlined,
  MobileOutlined,
  BellOutlined,
  AimOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const types = [
  { value: 1, label: "Telefon" },
  { value: 2, label: "Tablet" },
  { value: 3, label: "Laptop" },
  { value: 4, label: "Inne" },
];

const EditDeviceForm = ({ deviceUuid, deviceName }) => {
  const [editDeviceModalOpen, setEditDeviceModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [formData, setFormData] = useState({
    name: "",
    message: "",
    type: "",
    mobile: "",
    origin_mobile: "",
    email: "",
    is_email_notification_enabled: false,
    is_sms_notification_enabled: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const apiUrl = `${api.host}/accounts/plans/current`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch current plan");
        }

        const data = await response.json();
        setPlanInfo(data.currentPlan);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching current plan:", error);
      }
    };

    fetchCurrentPlan();
  }, [editDeviceModalOpen]);

  const fetchDeviceData = async () => {
    if (isLogged()) {
      setLoading(true);
      try {
        const response = await fetch(`${api.host}/devices/${deviceUuid}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        });
        const data = await response.json();
        setFormData({
          name: data.name,
          message: data.message,
          type: data.device_type_id,
          mobile: data.mobile,
          origin_mobile: data.origin_mobile,
          email: data.email,
          is_email_notification_enabled: data.is_email_notification_enabled,
          is_sms_notification_enabled: data.is_sms_notification_enabled,
        });
      } catch (error) {
        console.error("Error fetching device data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (editDeviceModalOpen) {
      fetchDeviceData();
    }
  }, [editDeviceModalOpen]);

  const updateDevice = async () => {
    if (!validateFormData()) return;

    setLoading(true);
    try {
      const response = await fetch(`${api.host}/devices/${deviceUuid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify({
          name: formData.name,
          message: formData.message,
          device_type_id: formData.type,
          email: formData.email,
          mobile: formData.mobile.replace(/\D/g, ""),
          origin_mobile: formData.origin_mobile.replace(/\D/g, ""),
          is_email_notification_enabled: formData.is_email_notification_enabled,
          is_sms_notification_enabled: formData.is_sms_notification_enabled,
        }),
      });
      await response.json();
      message.success("The luggage has been updated");
      dispatchUpdateActions();
      setEditDeviceModalOpen(false);
    } catch (error) {
      console.error("Error updating luggage:", error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteDevice = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this luggage?",
      content:
        "Deleting the luggage will return the assigned QR code to your account's code pool. The released QR code will be reused when adding a new device.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: deleteDevice,
    });
  };

  const deleteDevice = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${api.host}/devices/${deviceUuid}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
      });
      if (response.ok) {
        message.success("The luggage has been deleted.");
        dispatchUpdateActions();
        setEditDeviceModalOpen(false);
      } else {
        throw new Error("Failed to delete luggage");
      }
    } catch (error) {
      console.error("Error deleting luggage:", error);
    } finally {
      setLoading(false);
    }
  };

  const dispatchUpdateActions = () => {
    dispatch({
      type: "transactionDataUpdated",
      payload: { transactionDataChanged: new Date().getSeconds() },
    });
    dispatch({
      type: "transactionUpdated",
      payload: { transactionUpdated: true },
    });
  };

  const validateFormData = () => {
    if (!formData.name || !formData.message || !formData.type) {
      message.error("All fields are required.");
      return false;
    }
    return true;
  };

  return (
    <>
      {contextHolder}
      <Button
        onClick={() => setEditDeviceModalOpen(true)}
        type="default"
        size="small"
      >
        {deviceName}
      </Button>

      <Modal
        title="Edit QR Tag"
        open={editDeviceModalOpen}
        onOk={updateDevice}
        onCancel={() => setEditDeviceModalOpen(false)}
        okText="Update"
        cancelText="Cancel"
        footer={[
          <Button key="delete" type="link" onClick={confirmDeleteDevice}>
            Delete
          </Button>,
          <Button key="cancel" onClick={() => setEditDeviceModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={updateDevice}>
            Update
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Space size={15} direction="vertical" style={{ width: "100%" }}>
            <Alert
              type="info"
              banner={true}
              description="Modifications do not affect the assigned QR code, so you don’t have to worry about the code stopping working."
            />
            <Input
              placeholder="Owner's name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <Input
              prefix={<MobileOutlined />}
              placeholder="Phone number to call if this luggage is found"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  mobile: e.target.value,
                })
              }
              value={formData.mobile}
            />
            <Input
              prefix={<MailOutlined />}
              placeholder="Email address to contact if this luggage is found"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  email: e.target.value,
                })
              }
              value={formData.email}
            />
            <TextArea
              placeholder="Wiadomość dla znalazcy"
              rows={4}
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
            />
          

            <Space direction="vertical">
              <h3 style={{ marginBottom: 0 }}>
                <BellOutlined /> Notifications
              </h3>
              <p style={{ marginTop: 0 }}>
                Notifications will be sent to the email and phone number
                provided as the contact in case of loss.
              </p>
            </Space>
            <Checkbox
              checked={formData.is_email_notification_enabled}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_email_notification_enabled: e.target.checked,
                })
              }
            >
              Send an email notification if the luggage is found
            </Checkbox>

            {planInfo && planInfo.plan.is_default === true ? (
              <>
                <Alert message="The free plan does not include SMS notifications for found luggage. To use this feature, consider upgrading to a paid plan."></Alert>
              </>
            ) : (
              <></>
            )}

            <Checkbox
              checked={formData.is_sms_notification_enabled}
              disabled={planInfo && planInfo.plan.is_default}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_sms_notification_enabled: e.target.checked,
                })
              }
            >
              Send an SMS notification if the luggage is found
            </Checkbox>
          </Space>
        </Spin>
      </Modal>
    </>
  );
};

export default EditDeviceForm;
