import React, { useEffect, useState } from 'react';
import locale from 'antd/locale/pl_PL';
import './App.css';
import { LockOutlined, SettingOutlined } from '@ant-design/icons';
import DevicesPage from './components/Dashboard/DevicesPage';
import AccountPage from './components/Dashboard/AccountPage';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { Layout, Menu, ConfigProvider, Button, Dropdown } from 'antd';

import WelcomePage from './components/Offer/WelcomePage';
import RegisterPage from './components/Register/RegisterPage';
import PricingPage from './components/Offer/PricingPage';
import JobPage from './components/Offer/JobPage';
import FeaturesPage from './components/Offer/FeaturesPage';
import AboutPage from './components/Offer/AboutPage';
import PasswordResetPage from './components/Login/PasswordResetPage';
import AcceptInvitationPage from './components/Login/AcceptInvitationPage';
import AccountDataPage from './components/Dashboard/Account/Data/AccountDataPage';
import AccountPlanPage from './components/Dashboard/Account/Plan/AccountPlanPage';
import PlanPickerPage from './components/Dashboard/Account/Plan/PlanPickerPage';
import ContactPage from './components/Offer/ContactPage';
import RegulationsPage from './components/Offer/RegulationsPage';
import PaymentThankyouPage from './components/Dashboard/Account/Plan/PaymentThankyouPage';
import CardPage from './components/Device/Card/CardPage';
import { HelmetProvider } from 'react-helmet-async';
import SeoArticle1 from './components/Offer/SeoArticle1';
import PrivacyPage from './components/Offer/PrivacyPage';
import LocationPage from './components/Offer/LocationPage';

const { Content, Header } = Layout;

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('authToken'));
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    setIsLoggedIn(false);
  }

  const menu = (
    <Menu>
    
      <Menu.Item key="6">
        <Link to="/account/plan">Payments</Link>
      </Menu.Item>
    </Menu>
  );

  // Check if the current route is /device/:uuid
  const isDeviceRoute = location.pathname.startsWith('/device/');

  return (
    <HelmetProvider>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: '#1677ff',
            borderRadius: '5px',
          },
        }}
      >
        <Layout>
          {!isDeviceRoute && (
            <>
              {isLoggedIn ? (
                <Header style={{ background: "#061641", height: "79px" }}>
                  <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} style={{ display: 'flex', background: "transparent", marginTop: "8px" }}>
                    <div className="logo"><Link to="/devices"><h2 className='brand'><img src="/img/cellid_logo.svg" /></h2></Link></div>
                    <Menu.Item key="4" style={{ background: "transparent", color: "#fff", right: "101px", position: "absolute", zIndex: 1000, top: "7px" }}>
                      <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                          <SettingOutlined />
                        </a>
                      </Dropdown>
                    </Menu.Item>
                    <Menu.Item style={{ background: "transparent", right: "4px", position: "absolute", zIndex: 1000, top: "7px" }} key="3">
                      <Button onClick={handleLogout} size='middle' type="primary">Logout</Button>
                    </Menu.Item>
                  </Menu>
                </Header>
              ) : (
                <Header style={{ background: "#061641", height: "79px" }}>
                  <Menu theme="dark" mode="horizontal" style={{ display: 'flex', background: "transparent" }}>
                    <div className="logo"><Link to="/"><h2 className='brand'><img src="/img/cellid_logo.svg" /></h2></Link></div>
                   
                    <Menu.Item style={{ background: "transparent", right: "4px", position: "absolute", zIndex: 1000, top: "7px" }} key="3">
                      <Link to="/devices">
                        <Button size='middle' type="primary" icon={<LockOutlined />}>My Tags</Button>
                      </Link>
                    </Menu.Item>
                  </Menu>
                </Header>
              )}
            </>
          )}

          <Content style={{padding:10}}>
            <Routes>
              <Route path="/" element={<LocationPage />} />
              <Route path="/ubezpieczenie-telefonu" element={<SeoArticle1 />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/location" element={<LocationPage />} />
              <Route path="/features" element={<FeaturesPage />} />
              <Route path="/job" element={<JobPage />} />
              <Route path="/regulations" element={<RegulationsPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/devices" element={<DevicesPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/account/data" element={<AccountDataPage />} />
              <Route path="/account/plan" element={<AccountPlanPage />} />
              <Route path="/account/payment/thankyou" element={<PaymentThankyouPage />} />
              <Route path="/account/plan/picker" element={<PlanPickerPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/device/:uuid" element={<CardPage />} />
              <Route path="/account/register" element={<RegisterPage />} />
              <Route path="/account/password-reset" element={<PasswordResetPage />} />
              <Route path="/account/accept-invitation/:invitationUuid" element={<AcceptInvitationPage />} />
            </Routes>

            {!isDeviceRoute && (
              <div style={{ width: "280px", margin: "auto", marginTop: "20px", marginBottom: "40px", background: "#fff", textAlign: "center", color: "#aaa" }}>
                <img src="/img/cellid_logo_inv.svg" alt="CellID Logo" style={{width:100}} />
                <br/>
                &copy; VirtualBagID - The best service for quickly contacting the owner of lost luggage
              </div>
            )}
          </Content>
        </Layout>
      </ConfigProvider>
    </HelmetProvider>
  );
};

export default App;
