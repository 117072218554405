import React, { useState } from "react";
import { Card, Input, Space, Button, Row, Col, message, Divider } from "antd";
import {
  UserOutlined,
  BlockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../config/api";
import { Link } from "react-router-dom";
import { destroyAuthToken, getAuthToken } from "../../helpers/account";
import { isPasswordSafe } from "../../helpers/validators";

const PasswordResetPage = () => {
  const [email, setEmail] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [codeVerifed, setCodeVerifed] = useState(false);
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleSendCode = () => {
    const apiUrl = `${api.host}/accounts/initiate-email-confirmation`;
    const requestBody = {
      email,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((data) => {
              setEmailConfirmed(true);
              messageApi.open({
                type: "success",
                content: "The authorization code has been sent to your email address.",
              });
            })
            .catch((error) => {
              messageApi.open({
                type: "error",
                content: "An error occurred while generating the authorization code.",
              });
            });
        } else {
          messageApi.open({
            type: "warning",
            content: "Invalid email address.",
          });
          destroyAuthToken();
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "warning",
          content: "Invalid email address.",
        });
        destroyAuthToken();
      });
  };

  const handleVerification = () => {
    if (code == "") {
      messageApi.open({
        type: "error",
        content: "You haven't entered the verification code.",
      });
      return;
    }

    const apiUrl = `${api.host}/accounts/email-confirmation`;
    const requestBody = {
      email,
      code,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          setCodeVerifed(true);
          response
            .json()
            .then((data) => {
              messageApi.open({
                type: "success",
                content: "The account has been verified.",
              });
              sessionStorage.setItem("authToken", data.authToken);
              sessionStorage.setItem("uuid", data.uuid);
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Incorrect verification code. Please try again.",
        });
      });
  };

  const handleReset = () => {
    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "The passwords do not match. Please try again.",
      });
      return;
    }

    if (!isPasswordSafe(password)) {
      messageApi.open({
        type: "error",
        content:
          "The password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character (!@#$%^&*).",
      });
      return;
    }

    const apiUrl = `${api.host}/accounts/reset-password`;
    const requestBody = {
      newPassword: password,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      }),
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          messageApi.open({
            type: "success",
            content: "The account password has been reset.",
          });
        }
        destroyAuthToken();
        setTimeout(() => {
          window.location.href = "/devices";
        }, 2000);
      })
      .catch((error) => {
        messageApi.open({
          type: "error",
          content: "Errors occurred during the password reset process.",
        });
        destroyAuthToken();
        setTimeout(() => {
          window.location.href = "/devices";
        }, 2000);
      });
  };

  return (
    <Row style={{ height: 500, justifyContent: "center", minHeight: "100vh" }}>
      {contextHolder}
      <Col style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1 }}>
        <Card
          title={
            <>
              <UserOutlined /> Password Reset
            </>
          }
          extra={
            <Link to="/">
              <CloseCircleOutlined style={{ color: "#8c8c8c" }} />
            </Link>
          }
          style={{ width: 350, boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)" }}
        >
          <Space size={30} direction="vertical">
            <Input size="large" autoComplete="off" placeholder="Email address" prefix={<UserOutlined />} value={email} disabled={codeVerifed} onChange={(e) => setEmail(e.target.value)} />
            {emailConfirmed && !codeVerifed ? (
              <>
                <p>Enter the verification code we sent to your email address.</p>
                <Input size="large" autoComplete="off" placeholder="Verification code" prefix={<BlockOutlined />} value={code} onChange={(e) => setCode(e.target.value)} />
                <Button type="primary" onClick={handleVerification}>Verify email address</Button>
              </>
            ) : null}
            {codeVerifed ? (
              <>
                <Input.Password size="large" autoComplete="off" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <Input.Password size="large" autoComplete="off" placeholder="Repeat password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                <Button type="primary" onClick={handleReset}>Change password</Button>
              </>
            ) : null}
            {!emailConfirmed && !codeVerifed ? (
              <Button type="primary" onClick={handleSendCode}>Send code to email</Button>
            ) : null}
            <p>If you don’t have an account yet, <Link to="/account/register">create a free one</Link>.</p>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default PasswordResetPage;
