import React, { useState, useEffect } from "react";
import {
  Card,
  Input,
  Space,
  Button,
  Row,
  Col,
  message,
  Steps,
  Spin,
  Checkbox,
  Typography,
} from "antd";
import {
  UserOutlined,
  BlockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { api } from "../../config/api";
import { isEmailValid, isPasswordSafe } from "../../helpers/validators";
import { Link } from "react-router-dom";

const { Text } = Typography;

const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [regulationsAccepted, setRegulationsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [savedDeviceData, setSavedDeviceData] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setSavedDeviceData({ type: 4 });
  }, []);

  const getDeviceIcon = (deviceType) => {
    switch (deviceType) {
      case 1:
        return (
          <img
            src="/img/mobile-phone.png"
            alt="Mobile"
            style={{ width: 64, height: 64 }}
          />
        );
      case 2:
        return (
          <img
            src="/img/tablet.png"
            alt="Tablet"
            style={{ width: 64, height: 64 }}
          />
        );
      case 3:
        return (
          <img
            src="/img/laptop.png"
            alt="Laptop"
            style={{ width: 64, height: 64 }}
          />
        );
      case 4:
      default:
        return (
          <img
            src="/img/3275919.png"
            alt="Other"
            style={{ width: 64, height: 64 }}
          />
        );
    }
  };

  const steps = [
    {
      title: "Create Account",
    },
    {
      title: "Verify Email",
    },
    {
      title: "Add QR Tags",
    },
  ];

  const handleRegulations = (e) => {
    setRegulationsAccepted(e.target.checked);
  };

  const handleRegister = () => {
    if (isEmailValid(email) !== true) {
      messageApi.open({
        type: "error",
        content: "Invalid email address.",
      });
      return;
    }

    if (password !== confirmPassword) {
      messageApi.open({
        type: "error",
        content: "Passwords do not match. Try again.",
      });
      return;
    }

    if (!isPasswordSafe(password)) {
      messageApi.open({
        type: "error",
        content:
          "Password must be at least 8 characters and include an uppercase letter, a number, and a special character (!@#$%^&*).",
      });
      return;
    }

    if (!regulationsAccepted) {
      messageApi.open({
        type: "error",
        content: "You must accept the terms and conditions.",
      });
      return;
    }

    setLoading(true);
    const apiUrl = `${api.host}/accounts`;

    const requestBody = {
      email,
      password,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 201) {
          messageApi.open({
            type: "success",
            content: "Account created. Please verify your email.",
          });
          setCurrentStep(1);
        } else {
          messageApi.open({
            type: "error",
            content: "An account with this email already exists.",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "An account with this email already exists.",
        });
      });
  };

  const handleVerification = () => {
    if (code === "") {
      messageApi.open({
        type: "error",
        content: "You didn't enter the verification code.",
      });
      return;
    }

    const apiUrl = `${api.host}/accounts/email-confirmation`;
    setLoading(true);

    const requestBody = {
      email,
      code,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.status === 200) {
          messageApi.open({
            type: "success",
            content: "The account has been verified.",
          });
          setCurrentStep(1);
          setTimeout(() => {
            window.location.href = "/devices";
          }, 2000);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        messageApi.open({
          type: "error",
          content: "Invalid verification code. Please try again.",
        });
      });
  };

  return (
    <Row
      style={{
        justifyContent: "center",
      }}
    >
      {contextHolder}
      <Col
        style={{
          maxWidth: "350px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          marginTop: "20px",
        }}
      >
        <Space size={30} direction="vertical">
          <Space size={10}>
            <h2 className="offer">
              Good choice! Lose the Worry, Not Your Luggage.
            </h2>
          </Space>

          {savedDeviceData && (
            <Card>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "10px" }}>
                  {getDeviceIcon(savedDeviceData.type)}
                </div>
                <Text level={4}>
                  You're just one step away from ensuring that if your bag goes
                  missing, your contact details remain accessible for a safe
                  return.
                </Text>
              </div>
            </Card>
          )}

          <Spin spinning={loading}>
            <Card
              title={
                <>
                  <img src="/img/devices.png" /> New Account
                </>
              }
              extra={
                <Link to="/">
                  <CloseCircleOutlined style={{ color: "#8c8c8c" }} />
                </Link>
              }
              style={{
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              <Space size={30} direction="vertical">
                <Steps size="small" current={currentStep} items={steps}></Steps>

                {currentStep === 0 ? (
                  <>
                    <Input
                      size="large"
                      autoComplete="off"
                      placeholder="Email Address"
                      prefix={<UserOutlined />}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <Input.Password
                      size="large"
                      autoComplete="off"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <Input.Password
                      size="large"
                      autoComplete="off"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    <Checkbox onChange={handleRegulations}>
                      I confirm that I have read and accept the{" "}
                      <Link to="/regulations">terms of service</Link> and{" "}
                      <Link to="/privacy">privacy policy</Link>
                    </Checkbox>

                    <Button type="primary" onClick={handleRegister}>
                      Create Free Account
                    </Button>
                  </>
                ) : (
                  <>
                    <p>Enter the verification code sent to your email.</p>
                    <Input
                      size="large"
                      autoComplete="off"
                      placeholder="Verification Code"
                      prefix={<BlockOutlined />}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                    <Button type="primary" onClick={handleVerification}>
                      Verify Email
                    </Button>
                  </>
                )}
              </Space>
            </Card>
          </Spin>
        </Space>
      </Col>
    </Row>
  );
};

export default RegisterPage;
