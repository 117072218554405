import { Button, Space, Row, Col, Typography, Divider, Steps } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CheckOutlined } from "@ant-design/icons";
const { Title } = Typography;

const LocationPage = () => {
  const [visible, setVisible] = useState(null);
  const [deviceType, setDeviceType] = useState(1);
  const [deviceName, setDeviceName] = useState("");

  const handleCalendlyClick = (event) => {
    event.preventDefault();
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/smieciappka?hide_gdpr_banner=1",
      });
    } else {
      console.error("Calendly is not loaded.");
    }
  };

  const handleAddDevice = () => {
    const deviceData = {
      name: deviceName,
      type: deviceType,
    };

    localStorage.setItem("deviceData", JSON.stringify(deviceData));

    logGAEvent("click", {
      event_category: "engagement",
      event_label: headingVariant,
      value: 1,
    });

    window.location.href = "/account/account/register";
  };

  const headingVariants = [
    "Pobierz tapetę na ekran blokady z kodem QR do kontaktu w razie zguby",
  ];

  const getHeadingVariant = () => {
    const storedVariant = localStorage.getItem("headingVariant");
    if (storedVariant) {
      return storedVariant;
    } else {
      const randomVariant =
        headingVariants[Math.floor(Math.random() * headingVariants.length)];
      localStorage.setItem("headingVariant", randomVariant);
      return randomVariant;
    }
  };

  const headingVariant = getHeadingVariant();

  const logGAEvent = (eventName, eventParams) => {
    if (window.gtag) {
      window.gtag("event", eventName, eventParams);
    }
  };

  const questions = [
    {
      key: "1",
      label: "Czy muszę zapłacić za zarejestrowanie mojego urządzenia",
      content: (
        <p>
          Nie, rejestracja urządzenia w systemie CellID jest całkowicie darmowa.
          Zachęcamy do rejestrowania wszystkich swoich urządzeń, aby zwiększyć
          szanse na ich odzyskanie w przypadku zagubienia.
        </p>
      ),
    },
    {
      key: "2",
      label: "Czy system działa na wszystkie rodzaje urządzeń?",
      content: (
        <p>
          Tak, system CellID działa na wszystkie rodzaje urządzeń, w tym
          telefony, tablety, laptopy i inne. Niezależnie od rodzaju urządzenia,
          możesz je zarejestrować i korzystać z naszych usług.
        </p>
      ),
    },
    {
      key: "3",
      label: "Jakie są opcje odzyskania mojego urządzenia?",
      content: (
        <p>
          Jeśli Twoje urządzenie zostanie znalezione przez uczciwego znalazcę,
          będzie mógł on skontaktować się z Tobą za pośrednictwem danych
          kontaktowych podanych w systemie CellID.
        </p>
      ),
    },
    {
      key: "4",
      label:
        "Co się stanie, jeśli moje urządzenie zostanie znalezione przez kogoś nieuczciwego?",
      content: (
        <p>
          Niestety, nie możemy zagwarantować, że każde urządzenie zostanie
          zwrócone, zwłaszcza jeśli trafi w ręce osoby nieuczciwej. Jednakże
          rejestracja w systemie CellID zwiększa szanse na odzyskanie
          urządzenia, ponieważ uczciwy znalazca będzie miał możliwość łatwego
          skontaktowania się z właścicielem.
        </p>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>VirtualBagID - Free Virtual QR Bag Tag for Travelers</title>
      </Helmet>

      <Space size={20} direction="vertical">
        <Row justify="space-between" align="middle" gutter={12}>
          <Col md={4}></Col>
          <Col md={8}>
            <Space direction="vertical" size={30}>
              <Title>
                Free Virtual QR Bag Tag for Travelers – Print at Home & Travel
                Worry-Free
              </Title>

              <Steps
                direction="vertical"
                size="small"
                current={0}
                items={[
                  {
                    title:
                      "Enter your contact details, and we’ll create a unique digital ID for your luggage",
                  },
                  {
                    title:
                      "Download your personalized bag tags and print them at home",
                  },
                  {
                    title:
                      "If your bag is lost, anyone who finds it can scan the tag and contact you instantly",
                  },
                ]}
              ></Steps>

              <a href="/account/register">
                <Button size="large" type="primary" style={{ width: "100%" }}>
                  Get Your QR Luggage Tag Now
                </Button>
              </a>

              <Row justify="space-between" align="middle" gutter={12}>
                <Col xs={12}>
                  <p>
                    <CheckOutlined /> Receive email and SMS alerts when your QR
                    tag is scanned.
                  </p>
                </Col>
                <Col xs={12}>
                  <p>
                    <CheckOutlined /> Update your contact info anytime without
                    replacing your tag
                  </p>
                </Col>
                <Col xs={12}>
                  <p>
                    <CheckOutlined /> Create a tag with your contact details,
                    and custom message.
                  </p>
                </Col>
                <Col xs={12}>
                  <p>
                    <CheckOutlined /> No more faded or unreadable handwritten
                    tags
                  </p>
                </Col>
              </Row>

              <Space direction="horizontal" align="center">
                <img src="/img/teachers.png" style={{ width: 40 }} />
                <p>
                  <strong>
                    <u>Trusted by Thousands of Travelers, Families, and Businesses</u>
                  </strong>
                </p>
              </Space>
              <p>
                For years, VirtualBagID.com has been helping travelers around
                the world keep their luggage safe and recover lost bags with
                ease. Our reliable and time-tested service has successfully
                reunited countless travelers with their belongings, providing
                peace of mind on every journey. With a history of proven
                success, we ensure that your contact details remain accessible,
                giving you the best chance of recovering your bag if it ever
                goes missing.
              </p>
            </Space>
          </Col>
          <Col md={8}>
            <div
              style={{
                position: "relative",
                display: "inline-block",
                width: "100%",
              }}
            >
              <img
                src="/img/traveler.svg"
                style={{ width: "100%", display: "block" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://cellid.pl/device/744adf3f-2756-4216-b122-04092257f0f4"
                  target="_blank"
                  className="shadow"
                >
                  <Button size="large" type="primary">Test VirtualBagID Demo</Button>
                </a>
              </div>
            </div>
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
        <Row justify="space-between" align="top" gutter={12}>
          <Col md={4}></Col>
          <Col md={8}>
            <Title level={3}>Use Cases for QR Luggage Tags</Title>

            <Row justify="space-between" align="middle" gutter={12}>
              <Col xs={12}>
                <p>
                  <CheckOutlined /> Family Vacations – Ensure your child's
                  luggage is easily identifiable and can be returned if lost.
                </p>
              </Col>
              <Col xs={12}>
                <p>
                  <CheckOutlined /> Business Travel – Keep your professional
                  gear and documents secure during work trips.
                </p>
              </Col>
              <Col xs={12}>
                <p>
                  <CheckOutlined /> Backpacking & Adventure Travel – Protect
                  your backpack while exploring new destinations.
                </p>
              </Col>
              <Col xs={12}>
                <p>
                  <CheckOutlined /> Students Studying Abroad – Make sure your
                  bags are labeled with up-to-date contact info. tags
                </p>
              </Col>
              <Col xs={12}>
                <p>
                  <CheckOutlined /> Elderly Travelers – Provide peace of mind by
                  ensuring luggage details remain legible and accessible.
                </p>
              </Col>
              <Col xs={12}>
                <p>
                  <CheckOutlined /> Sports & Outdoor Gear – Secure your ski,
                  golf, or camping bags with scannable tags.
                </p>
              </Col>
              <Col xs={12}>
                <p>
                  <CheckOutlined /> Event & Conference Travel – Avoid mix-ups
                  and ensure your branded gear is returned to you.
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={8}>
            <img src="/img/family-train-travel.jpg" style={{ width: "100%" }} />
          </Col>

          <Col md={4}></Col>
        </Row>
        <Divider></Divider>
      </Space>
    </>
  );
};

export default LocationPage;
