import React, { useRef, useState, useEffect } from "react";
import { Modal, Button, Checkbox } from "antd";
import QRCode from "qrcode.react";

const QRCodePopup = ({ visible, onClose, qrCodeUrl, device }) => {
  const qrRef = useRef();
  const printRef = useRef();
  const [qrImage, setQrImage] = useState("");
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector("canvas");
      if (canvas) {
        setQrImage(canvas.toDataURL("image/png"));
      }
    }
  }, [qrCodeUrl]);

  const handlePrint = () => {
    const printContent = printRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  return (
    <Modal open={visible} onCancel={onClose} footer={null} centered>
      <div ref={printRef}>
        <div
          style={{
            width: "85.6mm",
            height: "53.98mm",
            border: "2px solid black",
            display: "flex",
            padding: "5mm",
            fontFamily: "Arial, sans-serif",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {qrImage ? (
              <>
                <img
                  src="/img/cellid_logo_inv.svg"
                  alt="CellID Logo"
                  style={{ width: "100%" }}
                />
                <br />
                <img
                  src={qrImage}
                  alt="QR Code"
                  style={{ width: 80, height: 80 }}
                />
              </>
            ) : (
              <div ref={qrRef}>
                <img
                  src="/img/cellid_logo_inv.svg"
                  alt="CellID Logo"
                  style={{ width: "100%" }}
                />
                <br />
                <QRCode value={qrCodeUrl} size={80} />
              </div>
            )}
          </div>

          <div style={{ flex: 2, paddingLeft: "5mm" }}>
            <h3 style={{ margin: 0 }}>Owner’s Info</h3>
            <hr style={{ border: "1px solid black" }} />
            <p style={{ margin: "4px 0" }}>
              Name: {device ? device.name : "____________________"}
            </p>
            {showContact ? (
              <>
                <p style={{ margin: "4px 0" }}>
                  Phone: {device ? device.mobile : "____________________"}
                </p>
                <p style={{ margin: "4px 0" }}>
                  Email: {device ? device.email : "____________________"}
                </p>
              </>
            ) : (
              <></>
            )}
            <p style={{ fontSize: 12 }}>
              Scan the QR code to see the owner's contact details online.
            </p>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Checkbox onChange={(e) => setShowContact(e.target.checked)}>
          Show contact data on the tag
        </Checkbox>
        <br />
        <Button
          type="primary"
          onClick={handlePrint}
          style={{ marginTop: "10px" }}
        >
          Print Tag
        </Button>
      </div>
    </Modal>
  );
};

export default QRCodePopup;
