import React, { useState, useEffect } from "react";
import { Card, Typography, Spin, Alert, Button, Space, Divider } from "antd";
import { useSelector } from "react-redux";
import { api } from "../../../../config/api";
import { getAuthToken } from "../../../../helpers/account";
import { formatPolishDate } from "../../../../helpers/date";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const CurrentPlanInfo = () => {
  const [loading, setLoading] = useState(true);
  const [planInfo, setPlanInfo] = useState(null);
  const transactionDataChanged = useSelector(
    (state) => state.transactionDataChanged
  );

  useEffect(() => {
    const fetchCurrentPlan = async () => {
      try {
        const apiUrl = `${api.host}/accounts/plans/current`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `${getAuthToken()}`,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch current plan");
        }

        const data = await response.json();
        setPlanInfo(data.currentPlan);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching current plan:", error);
      }
    };

    fetchCurrentPlan();
  }, [transactionDataChanged]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!planInfo) {
    return (
      <Alert
        message="No payment plan"
        description="Your account does not have an active payment plan."
        type="info"
        showIcon
      />
    );
  }

  return (
    <Card
      title={
        <>
          <img src="/img/best-price.png" /> Current plan
        </>
      }
      style={{ maxWidth: "500px" }}
    >
      <Space direction="vertical">
        <Title
          level={4}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Space size={3}>

          {planInfo.plan.is_default === true && (
            <>
              <img src="/img/shopping.png" style={{width:"32px"}} />{" "}
            </>
          )}{" "}
          Plan "{planInfo.plan.name}"
          </Space>
        </Title>

        <Text>Active until: {formatPolishDate(planInfo.ends_at)}</Text>

        <Link to="/account/plan">Check plan usage</Link>
        {planInfo.plan.is_default === true && (
          <>
            {" "}
            <Link to="/account/plan/picker">
              <Button type="primary">Increase the number of luggage items</Button>
            </Link>
          </>
        )}
      </Space>
    </Card>
  );
};

export default CurrentPlanInfo;
